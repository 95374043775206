// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face { 
  font-family: Plex;
  src: url("../assets/fonts/IBMPlexSans-Medium.ttf");
}

/** Ionic CSS Variables **/
:root {
  // --ion-background-color: #dddddd;

  --ion-font-family: 'Plex'; 

  ion-list {
    padding: 0px;
  }

  ion-range {
    padding: 0px;
  }

  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
  --ion-grid-row-padding: 0px;

  ion-range::part(pin) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  
    z-index: 100000000000;
    position:relative;
    border-radius: 50%;
    transform: scale(.99);

    color: var(--ion-color-primary-contrast);
    background-color: var(--ion-color-primary);
    top: 11px;
  
    min-width: 28px;
    height: 28px;
    transition: transform 120ms ease, background 120ms ease;
  }

ion-card {
    --background: #eeeeee;
    --color: #ffffff;
    border: 1px solid #0a61ad;
}

ion-card-header {
    --color: #0a61ad;
}

ion-card-title {
    --color: #1d3030;
}

ion-card-subtitle {
    --color: #000000;
}

ion-card-content {
    color: #2F4F4F;
}

ion-item {
    --background: #eeeeee;
    --color: #0a61ad;
}

ion-menu ion-item {
  --background: #eeeeee;
}

.icon-inner {
  --color: #0a61ad;
}

  /** primary **/
  --ion-color-primary: #0a61ad !important;
  --ion-color-primary-rgb: 10,97,173 !important;
  --ion-color-primary-contrast: #ffffff !important;
  --ion-color-primary-contrast-rgb: 255,255,255 !important;
  --ion-color-primary-shade: #095598 !important;
  --ion-color-primary-tint: #2371b5 !important;

  --ion-color-secondary: #3dc2ff !important;
  --ion-color-secondary-rgb: 61,194,255 !important;
  --ion-color-secondary-contrast: #ffffff !important;
  --ion-color-secondary-contrast-rgb: 255,255,255 !important;
  --ion-color-secondary-shade: #36abe0 !important;
  --ion-color-secondary-tint: #50c8ff !important;

  --ion-color-tertiary: #5260ff  !important;
  --ion-color-tertiary-rgb: 82,96,255 !important;
  --ion-color-tertiary-contrast: #ffffff !important;
  --ion-color-tertiary-contrast-rgb: 255,255,255 !important;
  --ion-color-tertiary-shade: #4854e0 !important;
  --ion-color-tertiary-tint: #6370ff !important;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-sunday: #ff7e79;
  --ion-color-sunday-rgb: 255,126,121;
  --ion-color-sunday-contrast: #000000;
  --ion-color-sunday-contrast-rgb: 0,0,0;
  --ion-color-sunday-shade: #e06f6a;
  --ion-color-sunday-tint: #ff8b86;

  --ion-color-monday: #ffd479;
  --ion-color-monday-rgb: 255,212,121;
  --ion-color-monday-contrast: #000000;
  --ion-color-monday-contrast-rgb: 0,0,0;
  --ion-color-monday-shade: #e0bb6a;
  --ion-color-monday-tint: #ffd886;

  --ion-color-tuesday: #fffc79;
  --ion-color-tuesday-rgb: 255,252,121;
  --ion-color-tuesday-contrast: #000000;
  --ion-color-tuesday-contrast-rgb: 0,0,0;
  --ion-color-tuesday-shade: #e0de6a;
  --ion-color-tuesday-tint: #fffc86;

  --ion-color-wednesday: #d4fb79;
  --ion-color-wednesday-rgb: 212,251,121;
  --ion-color-wednesday-contrast: #000000;
  --ion-color-wednesday-contrast-rgb: 0,0,0;
  --ion-color-wednesday-shade: #bbdd6a;
  --ion-color-wednesday-tint: #d8fb86;

  --ion-color-thursday: #73fcd6;
  --ion-color-thursday-rgb: 115,252,214;
  --ion-color-thursday-contrast: #000000;
  --ion-color-thursday-contrast-rgb: 0,0,0;
  --ion-color-thursday-shade: #65debc;
  --ion-color-thursday-tint: #81fcda;

  --ion-color-friday: #73fdff;
  --ion-color-friday-rgb: 115,253,255;
  --ion-color-friday-contrast: #000000;
  --ion-color-friday-contrast-rgb: 0,0,0;
  --ion-color-friday-shade: #65dfe0;
  --ion-color-friday-tint: #81fdff;

  --ion-color-saturday: #73fa79;
  --ion-color-saturday-rgb: 115,250,121;
  --ion-color-saturday-contrast: #000000;
  --ion-color-saturday-contrast-rgb: 0,0,0;
  --ion-color-saturday-shade: #65dc6a;
  --ion-color-saturday-tint: #81fb86;

  --ion-color-icon: #009;
  --ion-color-icon-rgb: 56, 128, 255;
  --ion-color-icon-contrast: #ffffff;
  --ion-color-icon-contrast-rgb: 255, 255, 255;
  --ion-color-icon-shade: #3171e0;
  --ion-color-icon-tint: #4c8dff;



}

.ion-color-icon {
  --ion-color-base: var(--ion-color-icon) !important;
  --ion-color-base-rgb: var(--ion-color-icon-rgb) !important;
  --ion-color-contrast: var(--ion-color-icon-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-icon-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-icon-shade) !important;
  --ion-color-tint: var(--ion-color-icon-tint) !important;
}

.ion-color-sunday {
  --ion-color-base: var(--ion-color-sunday) !important;
  --ion-color-base-rgb: var(--ion-color-sunday-rgb) !important;
  --ion-color-contrast: var(--ion-color-sunday-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-sunday-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-sunday-shade) !important;
  --ion-color-tint: var(--ion-color-sunday-tint) !important;
}

.ion-color-monday {
  --ion-color-base: var(--ion-color-monday) !important;
  --ion-color-base-rgb: var(--ion-color-monday-rgb) !important;
  --ion-color-contrast: var(--ion-color-monday-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-monday-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-monday-shade) !important;
  --ion-color-tint: var(--ion-color-monday-tint) !important;
}

.ion-color-tuesday {
  --ion-color-base: var(--ion-color-tuesday) !important;
  --ion-color-base-rgb: var(--ion-color-tuesday-rgb) !important;
  --ion-color-contrast: var(--ion-color-tuesday-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-tuesday-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-tuesday-shade) !important;
  --ion-color-tint: var(--ion-color-tuesday-tint) !important;
}

.ion-color-wednesday {
  --ion-color-base: var(--ion-color-wednesday) !important;
  --ion-color-base-rgb: var(--ion-color-wednesday-rgb) !important;
  --ion-color-contrast: var(--ion-color-wednesday-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-wednesday-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-wednesday-shade) !important;
  --ion-color-tint: var(--ion-color-wednesday-tint) !important;
}

.ion-color-thursday {
  --ion-color-base: var(--ion-color-thursday) !important;
  --ion-color-base-rgb: var(--ion-color-thursday-rgb) !important;
  --ion-color-contrast: var(--ion-color-thursday-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-thursday-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-thursday-shade) !important;
  --ion-color-tint: var(--ion-color-gthursdayold-tint) !important;
}

.ion-color-friday {
  --ion-color-base: var(--ion-color-friday) !important;
  --ion-color-base-rgb: var(--ion-color-friday-rgb) !important;
  --ion-color-contrast: var(--ion-color-friday-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-friday-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-friday-shade) !important;
  --ion-color-tint: var(--ion-color-friday-tint) !important;
}

.ion-color-saturday {
  --ion-color-base: var(--ion-color-saturday) !important;
  --ion-color-base-rgb: var(--ion-color-saturday-rgb) !important;
  --ion-color-contrast: var(--ion-color-saturday-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-saturday-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-saturday-shade) !important;
  --ion-color-tint: var(--ion-color-saturday-tint) !important;
}
